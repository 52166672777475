import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import faFacebook from '../fa-facebook.PNG';
import faTwitter from '../fa-twitter.PNG';
import faYoutube from '../fa-youtube.PNG';
import logo from '../ffstars-logo.png';
import fplLogo from '../fpl_banner.png';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function descendingComparator(a, b, orderBy, order) {
    if(a[orderBy] === 'N/A'){
        return order === 'desc' ? 1 : -1;
    }
    if(b[orderBy] === 'N/A'){
        return order === 'desc' ? -1 : 1;
    }
    if(orderBy === 'name' || orderBy === 'team_name'){
        return a[orderBy].localeCompare(b[orderBy]);
    }
    if(orderBy === 'hof_points'){
      orderBy = 'position';
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'position', numeric: true, disablePadding: true, label: 'Позиция' },
  { id: 'player_name', numeric: false, disablePadding: false, label: 'Име' },
  { id: 'hof_points', numeric: true, disablePadding: false, label: 'ffstars all-time рейтинг' },
  // { id: 'team_name', numeric: false, disablePadding: false, label: 'Отбор' },
  { id: 'pos_2019_20', numeric: true, disablePadding: false, label: 'Сезон 19-20' },
  { id: 'pos_2018_19', numeric: true, disablePadding: false, label: 'Сезон 18-19' },
  { id: 'pos_2017_18', numeric: true, disablePadding: false, label: 'Сезон 17-18' },
  { id: 'pos_2016_17', numeric: true, disablePadding: false, label: 'Сезон 16-17' },
  { id: 'pos_2015_16', numeric: true, disablePadding: false, label: 'Сезон 15-16' },
  { id: 'pos_2014_15', numeric: true, disablePadding: false, label: 'Сезон 14-15' },
  { id: 'pos_2013_14', numeric: true, disablePadding: false, label: 'Сезон 13-14' },
  { id: 'pos_2012_13', numeric: true, disablePadding: false, label: 'Сезон 12-13' },
  { id: 'pos_2011_12', numeric: true, disablePadding: false, label: 'Сезон 11-12' },
  { id: 'pos_2010_11', numeric: true, disablePadding: false, label: 'Сезон 10-11' },
];

function EnhancedTableHead(props) {

  
  const mobile = !useMediaQuery('(min-width:600px)');

  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={headCell.id === 'hof_points' ? classes.boldHeader : null}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.id == 'hof_points' ? (mobile ? 
                <div className={classes.columnWithArrow}>
                  <span>{headCell.label}</span>
                  <ArrowForwardIcon></ArrowForwardIcon>
                </div>
                : headCell.label) : headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  red : {
    color : 'red',
  },
  green : {
    color : 'green',
  },
  boldHeader : {
    fontWeight : 'bold',
  },
  columnWithArrow : {
    display : 'flex',
    alignItems : 'center',
  },
  root: {
    width: '100%',
    display : 'flex',
    'flex-direction' : 'column',

  },
  img : {
    margin : '16px 0px 16px 0px',
  },
  imgMobile : {
    display : 'flex',
    'justify-content' : 'center',
  },
  paper: {
    width: '90%',
    display : 'flex',
    'flex-direction' : 'column',
    'justify-content' : 'center',
    margin : 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterInput : {
    width : '30%',
    margin : '16px',
    minWidth : '300px',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  profileLink : {
    color: "black",
  },
  contentContainer : {
    maxWidth : '1140px',
    width: '90%',
    margin : 'auto'
  },
  headerContainer : {
    width: '90%',
    maxWidth : '1140px',
    height: '100%',
    margin : 'auto',
    display : 'flex',
    'justify-content' : 'space-between',
    'align-items' : 'center',
    'flex-wrap' : 'wrap',
  },
  headerContainerMobile : {
    paddingTop : '8px',
    paddingBottom : '8px',
    width: '100%',
    margin : 'auto',
    display : 'flex',
    'justify-content' : 'center',
    'align-items' : 'center',
    'flex-direction' : 'column',
  },
  header : {
    backgroundColor : '#00CC66',
    width : '100%',
    // height : '43px',
  },
  headerText : {
    color : 'white',
  },
  headerIcon : {
    fontFamily: 'Font Awesome 5 Brands',
  },
  selectRoot : {
    marginLeft : '0px',
    marginRight : '0px',
  },
  select : {
    paddingLeft : '0px',
  },
  actions : {
    marginLeft : '0px',
  },
  paginatorRoot : {
    padding : '0px',
  },
  footerText : {
    marginBottom : '64px',
  },
  readMoreVisible : {
    display : 'inline',
  },
  readMoreInvisible : {
    display : 'none',
  }
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('position');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [readMore, setReadMore] = React.useState(false);
  const mobile = !useMediaQuery('(min-width:600px)');
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  
  function getRankings(text){
    axios.get('https://ranking.fplfm.com?text=' + text)
    .then(response => {
      setRows(response.data);
      setPage(0);
    })
  }

  useEffect(()=> {
    getRankings("");
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterRowsHandler = (event) => {
    const searchText = event.target.value;
    getRankings(searchText);
  }

  const handleReadMore = (readMore) => {
    setReadMore(readMore);
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={mobile? classes.headerContainerMobile : classes.headerContainer}>
           <Typography className={classes.headerText}>
            Последвайте ни в социалните мрежи
           </Typography>
            <Typography className={classes.headerText}>
              {/* //TODO fix for iPhone .. */}
              <a title="" target="_blank" rel="noopener noreferrer" href={ mobile && isAndroid ? "fb://page/2819055064833241" : "https://www.facebook.com/ffstarsfpl/"} data-original-title="Facebook">
                <img src={faFacebook} alt="facebook" />
              </a>
              <a title="" target="_blank" rel="noopener noreferrer" href="https://twitter.com/FfstarsC" data-original-title="Twitter">
              <img src={faTwitter} alt="twitter" />
              </a>
              <a title="" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCTvWhxQ0-TPu3_CT0TYma-Q?view_as=subscriber" data-original-title="YouTube">
              <img src={faYoutube} alt="youtube" />
              </a>
          </Typography>
        </div>
      </div>
      <div className={mobile? classes.headerContainerMobile : classes.headerContainer}>
        <a className={mobile ? classes.imgMobile : classes.img} href="http://ffstars.com" target="_blank" rel="noopener noreferrer">
          <img width={mobile ? "90%" : "360px"} height={mobile ? "88px" : "77px"} src={logo} alt="ffstars-logo"></img>
        </a>
        <a className={mobile ? classes.imgMobile : classes.img} href="https://www.palmsbet.com/bg/fantasy/?marketingCode=FANTASY" target="_blank" rel="noopener noreferrer">
          <img width={mobile ? "90%" : "300px"} height="auto" src={fplLogo} alt="fplfm"></img>
        </a>
      </div>
      
      <Typography align="justify" className={classes.contentContainer}>
        Вечното класиране на ffstars показва най-добрите български фентъзи мениджъри за всички времена. <Button color="primary" onClick={() => handleReadMore(true)} className={readMore ? classes.readMoreInvisible : classes.readMoreVisible}>Виж повече</Button>
        <span className={readMore ? classes.readMoreVisible : classes.readMoreInvisible}> 
          В сезонния ранкинг се взимат предвид крайните класирания на всеки мениджър в последните 10 пълни сезона във Fantasy Premier League, като по-скорошните сезони имат по-голяма тежест. При липсващи сезони се гледа цялостното представяне на мениджъра през сезоните, в които има резултат. Минимумът за получаване на “ffstars all-time рейтинг” в сезонния ранкинг е 3 завършени сезона във FPL, както и членство във фентъзи лигата на ffstars (Влез с 1 клик <a href="https://fantasy.premierleague.com/leagues/auto-join/fwow4v" target="_blank" rel="noopener noreferrer">тук</a>. код: fwow4v).
          <br></br>
          <br></br>
          При присъединяване към фентъзи лигата на ffstars играта присъединява новите членове чак при първия следващ ъпдейт на FPL (най-често след началото на всеки нов Gameweek)
        </span>
        <Button color="primary" onClick={() => handleReadMore(false)}  className={readMore ? classes.readMoreVisible : classes.readMoreInvisible}>Скрий</Button>
      </Typography>
      <Paper className={classes.paper}>
        <TextField 
            variant="outlined"
            className={classes.filterInput} 
            label="Търси по име"
            onChange={filterRowsHandler}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }} >
        </TextField>
        {rows.length > 0 ?
        <div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);

                    return (
                      <TableRow
                        hover
                      //   onClick={event => handleClick(event, row.name)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell align="right">{row.position}</TableCell>
                        <TableCell align="left"><a rel="noopener noreferrer" className={classes.profileLink} href={"https://fantasy.premierleague.com/entry/" + row.player_id + "/history"} target="_blank">{row.player_name.split(" ")[0]}<br></br>{row.player_name.split(" ")[1]}</a></TableCell>
                        <TableCell align={mobile ? "center" : "right"} className={classes.boldHeader}>{row.hof_points}</TableCell>
                        {/* <TableCell align="left">{row.team_name}</TableCell> */}
                        <TableCell align="right" className={row.pos_2019_20==='N/A' ? classes.red : classes.green}>{row.pos_2019_20}</TableCell>
                        <TableCell align="right" className={row.pos_2018_19==='N/A' ? classes.red : classes.green}>{row.pos_2018_19}</TableCell>
                        <TableCell align="right" className={row.pos_2017_18==='N/A' ? classes.red : classes.green}>{row.pos_2017_18}</TableCell>
                        <TableCell align="right" className={row.pos_2016_17==='N/A' ? classes.red : classes.green}>{row.pos_2016_17}</TableCell>
                        <TableCell align="right" className={row.pos_2015_16==='N/A' ? classes.red : classes.green}>{row.pos_2015_16}</TableCell>
                        <TableCell align="right" className={row.pos_2014_15==='N/A' ? classes.red : classes.green}>{row.pos_2014_15}</TableCell>
                        <TableCell align="right" className={row.pos_2013_14==='N/A' ? classes.red : classes.green}>{row.pos_2013_14}</TableCell>
                        <TableCell align="right" className={row.pos_2012_13==='N/A' ? classes.red : classes.green}>{row.pos_2012_13}</TableCell>
                        <TableCell align="right" className={row.pos_2011_12==='N/A' ? classes.red : classes.green}>{row.pos_2011_12}</TableCell>
                        <TableCell align="right" className={row.pos_2010_11==='N/A' ? classes.red : classes.green}>{row.pos_2010_11}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{
              selectRoot: classes.selectRoot,
              select : classes.select,
              actions : classes.actions,
              'MuiIconButton-root' : classes.paginatorRoot,
            }}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
         :
          <Typography align="justify" className={classes.contentContainer + ' , ' + classes.footerText}>
            <strong>Няма намерен резултат. Ако все още не си член на  фентъзи лигата на ffstars, натисни на "ВИЖ ПОВЕЧЕ"</strong>
          </Typography> 
         }
      </Paper>
      <Typography align="justify" className={classes.contentContainer + ' , ' + classes.footerText}>
      Очаквайте скоро и Live ранкинг, който взима предвид и моментното представяне на мениджърите в текущия сезон! Там ще получат рейтинг и мениджъри със само 2 завършени сезона, като към тях ще се прибави текущото им представяне през новия сезон.
      </Typography>
    </div>
  );
}
